import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/episode-layout.js";
import FabioCard from "../components/fabiorosado.js";
import Social from "../components/social.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "about-the-podcast"
    }}>{`About The Podcast`}</h1>
    <p><em parentName="p">{`Each developer has a unique journey that they undertook to get into tech and achieve their position we talk with them about that journey.`}</em></p>
    <p>{`There is a lot you can learn from other people experiences and Landing in Tech aims to share with you the journey of developers, their struggles, their successes.
By hearing other developer experiences, you can have a better understanding of how to get into tech and avoid some pitfalls on your own journey to becoming a developer.`}</p>
    <p>{`We talk with developers twice a month on `}<a parentName="p" {...{
        "href": "https://twitch.tv/theflyingdev"
      }}>{`TheFlyingDev Twitch Channel`}</a>{`. `}</p>
    <p>{`Talking live is great because it allows you to interact with the viewers and to ask your questions to the guest of the day.`}</p>
    <p>{`After the talk, the raw video will be available on the channel for two weeks and then exported to `}<a parentName="p" {...{
        "href": "https://www.youtube.com/channel/UCUCYKCdnRhipFV3Tk852CcA"
      }}>{`YouTube`}</a>{`.
The audio will also be edited and published as a new episode after a week of the live talk.`}</p>
    <h2 {...{
      "id": "creator"
    }}>{`Creator`}</h2>
    <FabioCard mdxType="FabioCard" />
    <h2 {...{
      "id": "social-links"
    }}>{`Social links`}</h2>
    <Social mdxType="Social" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      